import ChannelMgmt from '../../components/Channel';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { useUser } from '../../components/UserContext';

function Container(): JSX.Element | null {
  const user = useUser();

  if (!user.organizer?.organization)
    return (
      <div className='w-full h-full flex items-center justify-center text-white'>
        This is only available to the organization members
      </div>
    );

  return (
    <ChannelMgmt org={user.organizer.organization} withLayout routePrefix='' />
  );
}

// eslint-disable-next-line import/no-default-export
export default function Home(): JSX.Element {
  return (
    <PublicHomeLayout>
      <Container />
    </PublicHomeLayout>
  );
}
